import { useState } from 'react';
import styled from 'styled-components';

import EditDatesModal from 'components/modals/EditDatesModal';
import { simpleDate } from 'lib/date';
import Episode from 'models/Episode';
import LocationEpisode from 'models/LocationEpisode';
import Profile from 'models/Profile';
import { RehabStateName } from 'models/RehabState';
import { colors } from 'styles/theme/colors';
import { H3, Label } from 'styles/typography';
import EditIcon from 'svg/EditIcon';

type Props = {
  patientName: string;
  locationEpisode: LocationEpisode;
  profile: Profile;
  episode: Episode;
};

export default function PatientRehabState({ locationEpisode, patientName, profile, episode }: Props) {
  const [showModal, setShowModal] = useState(false);

  const userCanEditStatusDates = profile.permissions.rehabStateEdit;

  const currentRehabState = episode.currentRehabState.state;

  const showLosChip =
    episode.trackLos && (locationEpisode.stayDescriptor === 'Past' || currentRehabState !== RehabStateName.Queue);

  const losText = `${locationEpisode.lengthOfStay} day${locationEpisode.lengthOfStay === 1 ? '' : 's'}`;

  const endDateText =
    locationEpisode.latest && locationEpisode.daysSinceEndDate == 0 ? 'present' : simpleDate(locationEpisode.endDate);

  const stateLabels = {
    [RehabStateName.Queue]: 'In Queue',
    [RehabStateName.Admission]: 'Admitted',
    [RehabStateName.InTreatment]: 'In Treatment',
    [RehabStateName.Discharged]: 'Discharged',
  };

  const headerText =
    locationEpisode.stayDescriptor !== 'Current'
      ? `${locationEpisode.stayDescriptor} Record`
      : stateLabels[currentRehabState] || 'Current Stay';

  return (
    <>
      {showModal && (
        <EditDatesModal setShow={setShowModal} patientName={patientName!} locationEpisode={locationEpisode} />
      )}
      <HeadSection>
        <div className='head-section-text'>
          <HeaderContainer>
            <H3>{headerText}</H3>
            {!episode.archived && !locationEpisode.archived && userCanEditStatusDates && (
              <ActionIcon data-cy='clickable' onClick={() => setShowModal(true)}>
                <EditIcon width={18} height={18} color={colors.black} />
              </ActionIcon>
            )}
          </HeaderContainer>
          {locationEpisode.currentRehabState.queue ? (
            <HeadDate>Added on {simpleDate(locationEpisode.currentRehabState.enteredAt)}</HeadDate>
          ) : (
            <HeadDate>
              {simpleDate(locationEpisode.startDate)} - {endDateText}
            </HeadDate>
          )}
        </div>
        {showLosChip && <DayCounterChip $latest={locationEpisode.latest}>{losText}</DayCounterChip>}
      </HeadSection>
    </>
  );
}

const HeadSection = styled.div`
  display: flex;
  padding: 24px;
  align-items: center;
  justify-content: space-between;
  .head-section-text {
    display: flex;
    flex-direction: column;
  }
`;
const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
const HeadDate = styled(Label)`
  margin-top: 6px;
  color: var(--black-50);
`;

const DayCounterChip = styled(H3)<{ $latest: boolean }>`
  background-color: ${(props) => (props.$latest ? 'var(--secondary-orange)' : 'var(--black-50)')};
  color: white;
  border-radius: 28px;
  white-space: nowrap;
  padding: 6px 11px;
  line-height: 16px;
`;

const ActionIcon = styled.div`
  height: 18px;
  cursor: pointer;
`;
